import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/Seo";

const Thanks = () => {
  return (
    <Layout>
      <SEO title={"Thank you!"} />
      <section className="px-2 md:px-4 pt-4 md:pt-8 space-y-2 mx-auto container text-center">
        <h1
          className={
            "text-gray-900 font-display text-4xl lg:text-5xl tracking-tight font-extrabold capitalize"
          }
        >
          Thank you!
        </h1>
        <p>Form submission successfully submitted.</p>
        <Link to="/" className="inline-block border-b border-primary text-sm">
          {`< `}Back to Home Page
        </Link>
      </section>
    </Layout>
  );
};

export default Thanks;
